


















import Vue from 'vue'
import SearchInstancesList from '@/views/components/search/SearchInstancesList.vue'

export default Vue.extend({
  components: {
    SearchInstancesList,
  },
  data() {
    return {
      searchInstances: {
        columns: [{ name: 'instanceName' }, { name: 'instanceId' }, { name: 'catalogName' }, { name: 'catalogId' }],
      },
    }
  },
  methods: {
    redirectToAddPage() {
      this.$router.push({ name: 'searchInstanceAdd' }).catch(() => {
      })
    },
  },
})
