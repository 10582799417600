<template>
  <div>
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
      <vs-col v-for="(instance, index) in instances" :key="index" vs-w="12">
        <b-card no-body>
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title>
                {{ instance.instanceName }}
              </b-card-title>
            </div>
            <b-dropdown
              variant="link"
              no-caret
              right
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                @click="goToSearchDetails(instance.instanceId)"
              >
                {{ $t("catalog.instance.showDetails") }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("search.instanceList.header.id") }}
              </dt>
              <dd class="col-sm-9">
                {{ instance.instanceId }}
              </dd>
            </dl>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("search.instanceList.header.name") }}
              </dt>
              <dd class="col-sm-9">
                {{ instance.instanceName }}
              </dd>
            </dl>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("search.instanceList.header.catalog") }}
              </dt>
              <dd class="col-sm-9">
                {{ instance.catalogName }}
              </dd>
            </dl>
          </b-card-body>
        </b-card>
      </vs-col>
    </b-overlay>
  </div>
</template>

<script>
import SearchInstancesStore from '@/store/search/searchInstances'

export default {
  computed: {
    instances() {
      return SearchInstancesStore.state.instances
    },
    dataLoading() {
      return SearchInstancesStore.state.dataLoading
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      return SearchInstancesStore.dispatch('getSearchInstancesData')
    },
    goToSearchDetails(searchId) {
      this.$router.push({ name: 'searchInstance', params: { searchId } })
    },
  },
}
</script>
